import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { S3_BUCKET_URL } from "../../Config";

import ProgressBar from "../components/ProgressBar/ProgressBar";
import { isRequestPending } from "../../state/api/selectors";
import { fetchCover } from "../../state/cover/actions";
import CookieConsent from "react-cookie-consent";

import "./Cover.scss";

class Cover extends Component {
	constructor(props) {
		super(props);
		this.onLogoClick = this.onLogoClick.bind(this);
	}

	componentDidMount() {
		this.props.fetchCover();
	}

	componentDidUpdate(prevProps) {
		if (this.props.previewImage !== prevProps.previewImage) {
			this.props.fetchCover();
		}
	}

	onLogoClick() {
		this.props.history.push("work");
	}

	render() {
		let coverImage = "";
		const { pendingRequest, cover = {} } = this.props;

		if (cover.images) {
			coverImage = S3_BUCKET_URL + encodeURIComponent(cover.images[0]);
		}

		return (
			<article className="cover">
				<header className="logo-container" onClick={this.onLogoClick}>
					<div role="navigation" aria-label="Cover" className="logo" />
				</header>
				{pendingRequest ? (
					<ProgressBar />
				) : (
					<div className="image-container">
						<img
							className="cover-image"
							src={coverImage}
							alt="Cover"
							onClick={this.onLogoClick}
						/>
					</div>
				)}
				<footer>
					<CookieConsent
						location="bottom"
						buttonText="Got it!"
						style={{ background: "#2B373B", textAlign: "center" }}
						buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
						expires={150}
					>
						This website uses cookies to ensure you get the best
						experience on our website.{" "}
						<a
							className="learn-more-link"
							href="https://cookiesandyou.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							Learn more
						</a>
					</CookieConsent>
				</footer>
			</article>
		);
	}
}

const mapStateToProps = state => ({
	cover: state.cover,
	pendingRequest: isRequestPending(state)("getCover")
});

const mapDispatchToProps = dispatch => ({
	fetchCover: () => dispatch(fetchCover())
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Cover)
);
