import * as ActionTypes from "./actions";

export const gallery = (state = { fullScreen: false }, { type, payload }) => {
	switch (type) {
		case ActionTypes.SET_FULLSCREEN:
			return {
				fullScreen: payload
			};
		default:
			return state;
	}
};
