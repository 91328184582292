import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, TextField } from "react-md";
import ProgressBar from "../components/ProgressBar/ProgressBar";

import { postLogin } from "../../state/auth/actions";
import { isRequestPending } from "../../state/api/selectors";

import "./Login.scss";

class Login extends Component {
	constructor(props) {
		super(props);

		this.onLogin = this.onLogin.bind(this);
		this.onUsernameChange = this.onUsernameChange.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.onPasswordKeyPress = this.onPasswordKeyPress.bind(this);
		this.state = {
			username: "",
			password: ""
		};
	}

	componentDidUpdate() {
		if (this.props.isAuthenticated) {
			this.props.history.push("/admin/cover");
		}
	}

  	onLogin() {
		const { username, password } = this.state;

		this.props.postLogin(username, password);
	}

	onUsernameChange(value) {
		this.setState({ username: value });
	}

	onPasswordChange(value) {
		this.setState({ password: value });
	}

	onPasswordKeyPress(event) {
		const {key = ''} = event;

		if (key === 'Enter') {
			this.onLogin();
		}
	}

	render() {
		const { username, password } = this.state;
		const { pendingRequest } = this.props;

		return (
			<div className="login">
				<div className="logo" />
				{pendingRequest ? <ProgressBar /> : (
					<form className="login-form">
						<TextField
							id="floating-center-title"
							label="User name"
							lineDirection="center"
							placeholder="User name"
							className="md-cell"
							value={username}
							onChange={this.onUsernameChange}
						/>
						<TextField
							id="floating-password"
							label="Password"
							placeholder="Password"
							type="password"
							className="md-cell"
							value={password}
							onChange={this.onPasswordChange}
							onKeyPress={this.onPasswordKeyPress}
						/>
						<Button
							raised
							disabled={!username || !password}
							className="login-button"
							onClick={this.onLogin}
						>
							Login
						</Button>
					</form>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	isAuthenticated: state.user.auth,
	pendingRequest: isRequestPending(state)("postLogin")
});

const mapDispatchToProps = dispatch => ({
	postLogin: (username, password) => dispatch(postLogin(username, password))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
