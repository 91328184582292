import { request } from "../api/actions";

export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_ERROR = "LOG_IN_ERROR";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOG_OUT_ERROR = "LOG_OUT_ERROR";
export const IS_AUTHENTICATED_SUCCESS = "IS_AUTHENTICATED_SUCCESS";
export const IS_AUTHENTICATED_ERROR = "IS_AUTHENTICATED_ERROR";

export const checkAuthenticationSuccess = result => ({
  type: IS_AUTHENTICATED_SUCCESS,
  payload: result
});

export const checkAuthenticationError = err => ({
  type: IS_AUTHENTICATED_ERROR,
  payload: err
});

export const checkAuthentication = () => async (dispatch, getState) => {
  request(dispatch, getState)(
    "checkAuthentication",
    {},
    checkAuthenticationSuccess,
    checkAuthenticationError
  );
};

export const postLoginSuccess = result => ({
  type: LOG_IN_SUCCESS,
  payload: result
});

export const postLoginError = err => ({
  type: LOG_IN_ERROR,
  payload: err
});

export const postLogin = (username, password) => async (dispatch, getState) => {
  request(dispatch, getState)(
    "postLogin",
    {
        data: {
            username: username,
            password: password
        }
    },
    postLoginSuccess,
    postLoginError
  );
};

export const getLogoutSuccess = result => ({
  type: LOG_OUT_SUCCESS,
  payload: result
});

export const getLogoutError = err => ({
  type: LOG_OUT_ERROR,
  payload: err
});

export const getLogout = () => async (dispatch, getState) => {
  request(dispatch, getState)(
    "getLogout",
    {},
    getLogoutSuccess,
    getLogoutError
  );
};
