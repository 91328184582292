import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import configureStore from "./state/store";
import { Provider } from "react-redux";

import Cover from "./view/Cover/Cover";
import Work from "./view/Work/Work";
import Contact from "./view/Contact/Contact";
import About from "./view/About/About";
import Admin from "./view/Admin/Admin";
import Login from "./view/Login/Login";

import "./index.scss";

// Create the store
const store = configureStore();

Sentry.init({
	dsn: "https://d41f15ac88004eda85a0631b2fdabd67@sentry.io/1406714"
});

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<Sentry.ErrorBoundary fallback={"An error has occured"}>
				<Switch>
					<Route exact path="/" component={Cover} />
					<Route path="/work" component={Work} />
					<Route path="/contact" component={Contact} />
					<Route path="/about" component={About} />
					<Route path="/admin" component={Admin} />
					<Route path="/login" component={Login} />
				</Switch>
			</Sentry.ErrorBoundary>
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);
