import React, { Component } from "react";
import { connect } from "react-redux";
import { getAbout } from "../../../state/admin/selectors";
import { uploadImage, fetchCollections, updateCollection } from "../../../state/admin/actions";
import { isRequestPending } from "../../../state/api/selectors";
import { Button, TextField, FileInput } from "react-md";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import About from "../../About/About";

import "./AboutConfig.scss";

class AboutConfig extends Component {
	constructor(props) {
		super(props);

		this.state = {
			aboutText: this.props.about?.title || ''
		};

		this.save = this.save.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleImageUpLoad = this.handleImageUpLoad.bind(this);
	}

	componentDidMount() {
		if (this.props.isAuthenticated) {
			this.props.fetchCollections();
		} else {
			this.props.history.push("/login");
		}
	}

	onChange(value) {
		this.setState(() => {
			return { aboutText: value };
		});
	}

	save() {
		const newAbout = Object.assign({}, this.props.about);
		newAbout.title = this.state.aboutText;

		this.props.updateCollection(newAbout);
	}

	getNewAbout(newImage) {
		const newAbout = Object.assign({}, this.props.about);
		newAbout.images = [newImage];

		return newAbout;
	}

	handleImageUpLoad(file) {
		if (file) {
			const newAbout = this.getNewAbout(file.name);
			this.props.uploadImage(file, newAbout);
		}
	}

	render() {
		const { aboutText } = this.state;
		const {
			pendingUpdateRequest,
			pendingLoadRequest,
			pendingUploadRequest
		} = this.props;

		return (
			<div className="about-config">
				{(pendingUpdateRequest ||
					pendingLoadRequest ||
					pendingUploadRequest) && <ProgressBar />}
				<div className="edit-container">
					<TextField
						id="about-text"
						label="About text"
						className="md-cell about-text"
						value={aboutText}
						onChange={this.onChange}
						rows={16}
					/>
					<div className="action-buttons">
						<Button
							flat
							className="save-button"
							onClick={this.save}
						>
							Save
						</Button>
						<FileInput
							id={"file-input"}
							accept="image/*"
							name="images"
							flat
							label="Select image"
							onChange={this.handleImageUpLoad}
						/>
					</div>
				</div>
				<div className="preview">
					<span>Preview</span>
					<About previewAbout={this.props.about} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	isAuthenticated: state.user.auth,
	about: getAbout(state),
	pendingUpdateRequest: isRequestPending(state)("updateAdminAbout"),
	pendingLoadRequest: isRequestPending(state)("getAbout"),
	pendingUploadRequest: isRequestPending(state)("uploadAdminImage")
});

const mapDispatchToProps = dispatch => ({
	updateCollection: collection => dispatch(updateCollection(collection)),
	fetchCollections: () => dispatch(fetchCollections()),
	uploadImage: (file, collection) => dispatch(uploadImage(file, collection))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AboutConfig);
