import { request } from "../api/actions";

export const RECEIVED_ABOUT_SUCCESS = "RECEIVED_ABOUT_SUCCESS";
export const RECEIVED_ABOUT_ERROR = "RECEIVED_ABOUT_ERROR";
export const ABOUT_UPDATE_SUCCESS = "ABOUT_UPDATE_SUCCESS";
export const ABOUT_UPDATE_ERROR = "ABOUT_UPDATE_ERROR";

export const getAboutSuccess = result => ({
  type: RECEIVED_ABOUT_SUCCESS,
  payload: result[0]
});

export const getAboutError = err => ({
  type: RECEIVED_ABOUT_ERROR,
  payload: err
});

export const fetchAbout = () => async (dispatch, getState) => {
  request(dispatch, getState)("getAbout", {}, getAboutSuccess, getAboutError);
};

export const updateAboutSuccess = result => ({
  type: ABOUT_UPDATE_SUCCESS,
  payload: result
});

export const updateAboutError = err => ({
  type: ABOUT_UPDATE_ERROR,
  payload: err
});

export const updateAbout = about => async (dispatch, getState) => {
  request(dispatch, getState)(
    "updateAdminAbout",
    {
      pathParams: {
        aboutId: about.id
      },
      data: {
        text: about.text,
        image: about.image
      }
    },
    updateAboutSuccess,
    updateAboutError
  );
};
