export const getWork = state => {
	const work = state.collections.filter(
		collection => collection.type === "work"
	);

	return work;
};

export const getCover = state => {
	const cover = state.collections.filter(
		collection => collection.type === "cover"
	)[0];

	return cover;
};

export const getAbout = state => {
	const about = state.collections.filter(
		collection => collection.type === "about"
	)[0];

	return about;
};

