export const endpoints = {
  getAbout: {
    url: "/about",
    method: "get"
  },
  getWork: {
    url: "/work",
    method: "get"
  },
  getCover: {
    url: "/cover",
    method: "get"
  },
  sendEmail: {
    url: "/contact",
    method: "post",
    data: {
      name: "",
      email: "",
      message: ""
    }
  },
  checkAuthentication: {
    url: "auth/validate",
    method: "get"
  },
  postLogin: {
    url: "auth/login",
    method: "post"
  },
  getLogout: {
    url: "logout",
    method: "get"
  },
  getAdminImages: {
    url: "admin/images",
    method: "get"
  },
  uploadAdminImage: {
    url: "admin/images",
    method: "put",
    headers: {
      "Content-Type": "content-type"
    }
  },
  deleteAdminImage: {
    url: "admin/images/:image",
    method: "delete"
  },
  getAdminCollections: {
    url: "admin/collections",
    method: "get"
  },
  updateAdminCollection: {
    url: "admin/collections/:collectionId",
    method: "put",
    data: {
      collection: {}
    }
  },
  deleteAdminCollection: {
    url: "admin/collections/:collectionId",
    method: "delete",
    data: {
      collection: {}
    }
  },
  addAdminCollection: {
    url: "admin/collections",
    method: "post",
    data: {
      type: "",
      title: ""
    }
  },
  updateAdminAbout: {
    url: "admin/about/:aboutId",
    method: "put",
    data: {
      text: ""
    }
  }
};

export default endpoints;
