import * as ActionTypes from "./actions";

export const about = (state = {}, { type, payload }) => {
	switch (type) {
		case ActionTypes.RECEIVED_ABOUT_SUCCESS:
			return payload;
		case ActionTypes.ABOUT_UPDATE_SUCCESS:
			return payload;
		default:
			return state;
	}
};
