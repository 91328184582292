import React, { Component } from "react";
import { connect } from "react-redux";
import {
	updateCollection,
	deleteCollection,
	addCollection
} from "../../../state/admin/actions";
import { Button, DialogContainer, TextField } from "react-md";
import CollectionCard from "./CollectionCard/CollectionCard";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { isRequestPending } from "../../../state/api/selectors";

import "./Collections.scss";

class Collections extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addDialogVisible: false,
			title: ""
		};

		this.addCollection = this.addCollection.bind(this);
		this.showAddDialog = this.showAddDialog.bind(this);
		this.hideAddDialog = this.hideAddDialog.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(value, event) {
		const target = event.target;

		this.setState({
			[target.name]: value
		});
	}

	addCollection() {
		this.props.addCollection(this.props.type, this.state.title);
		this.hideAddDialog();
	}

	showAddDialog() {
		this.setState({ addDialogVisible: true });
	}

	hideAddDialog() {
		this.setState({ addDialogVisible: false });
	}

	render() {
		const actions = [];
		actions.push(
			{
				secondary: true,
				children: "Cancel",
				onClick: this.hideAddDialog
			},
			{
				children: "Add",
				onClick: this.addCollection
			}
		);

		const { addDialogVisible, title } = this.state;
		const { 
			isCollectionRequestPending, 
			data,
			updateCollection,
			deleteCollection,
			pendingUpdateRequest,
			pendingDeleteRequest,
			pendingAddRequest
		} = this.props;

		const isPending = isCollectionRequestPending || pendingDeleteRequest || pendingAddRequest || pendingUpdateRequest;

		return (
			<div className={isPending ? "collections pending": "collections"}>
				<DialogContainer
					id="simple-action-dialog"
					visible={addDialogVisible}
					onHide={this.hideAddDialog}
					actions={actions}
					title="Create collection"
					className="new-collection-dialog"
				>
					<TextField
						id="simple-action-dialog-field"
						label="Collection title"
						name="title"
						value={title}
						onChange={this.handleChange}
					/>
				</DialogContainer>
				{isPending && <ProgressBar />}
				<div className="colleciton-card-container">
					{Array.from(data).map(collection => (
						<CollectionCard
							key={collection.id}
							collection={collection}
							updateCollection={updateCollection}
							deleteCollection={deleteCollection}
							isRequestPending={pendingUpdateRequest}
						/>
					))}
				</div>
				<Button icon primary className="add-button">
					<i className="material-icons" onClick={this.showAddDialog}>
						add
					</i>
				</Button>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	isAuthenticated: state.user.auth,
	pendingUpdateRequest: isRequestPending(state)("updateAdminCollection"),
	pendingDeleteRequest: isRequestPending(state)("deleteAdminCollection"),
	pendingAddRequest: isRequestPending(state)("addAdminCollection")
});

const mapDispatchToProps = dispatch => ({
	updateCollection: collection => dispatch(updateCollection(collection)),
	deleteCollection: collectionId => dispatch(deleteCollection(collectionId)),
	addCollection: (type, title) => dispatch(addCollection(type, title))
});

export default connect(mapStateToProps, mapDispatchToProps)(Collections);
