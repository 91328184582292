import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch } from "react-router-dom";
import { Button } from "react-md";

import { getLogout } from "../../state/auth/actions";
import { fetchCollections } from "../../state/admin/actions";
import { getWork } from "../../state/admin/selectors";
import { isRequestPending } from "../../state/api/selectors";

import Images from "./Images/Images";

import CoverConfig from "./CoverConfig/CoverConfig";
import Collections from "./Collections/Collections";
import AboutConfig from "./AboutConfig/AboutConfig";

import "./Admin.scss";

class Admin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addDialogVisible: false,
			selectedTab: "cover",
			title: ""
		};

		this.onLogoClick = this.onLogoClick.bind(this);
		this.onMenuItemClick = this.onMenuItemClick.bind(this);
	}

	componentDidMount() {
		if (this.props.isAuthenticated) {
			this.props.fetchCollections();
		} else {
			this.props.history.push("/login");
		}
	}

	onLogoClick() {
		this.props.getLogout();
		this.props.history.push("");
	}

	onMenuItemClick(event) {
		const path = event.target.getAttribute("path-param");
		const newPath = "/admin/" + path;

		this.setState({ selectedTab: path });
		this.props.history.push(newPath);
	}

	render() {
		const { selectedTab } = this.state;
		const { pendingRequest, work } = this.props;

		return (
			<div className="admin">
				<div className="logo-container" onClick={this.onLogoClick}>
					<div className="logo" />
				</div>
				<div className="menu">
					<Button
						flat
						primary
						className={`menu-button ${
							selectedTab === "cover" ? "selected" : ""
						}`}
						path-param="cover"
						onClick={this.onMenuItemClick}
					>
						Cover
					</Button>
					<Button
						flat
						primary
						className={`menu-button ${
							selectedTab === "work" ? "selected" : ""
						}`}
						path-param="work"
						onClick={this.onMenuItemClick}
					>
						Work
					</Button>
					<Button
						flat
						primary
						className={`menu-button ${
							selectedTab === "about" ? "selected" : ""
						}`}
						path-param="about"
						onClick={this.onMenuItemClick}
					>
						About
					</Button>
					<Button
						flat
						primary
						className={`menu-button ${
							selectedTab === "images" ? "selected" : ""
						}`}
						path-param="images"
						onClick={this.onMenuItemClick}
					>
						Images
					</Button>
				</div>
				<div className="main-container">
					<Switch>
						<Route path="/admin/cover" component={CoverConfig} />
						<Route
							path="/admin/work"
							render={() => (
								<Collections
									type="work"
									data={work}
									isCollectionRequestPending={pendingRequest}
								/>
							)}
						/>
						<Route path="/admin/about" component={AboutConfig} />
						<Route path="/admin/images" component={Images} />
					</Switch>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	isAuthenticated: state.user.auth,
	collections: state.collections,
	work: getWork(state),
	pendingRequest: isRequestPending(state)("getAdminCollections")
});

const mapDispatchToProps = dispatch => ({
	getLogout: () => dispatch(getLogout()),
	fetchCollections: () => dispatch(fetchCollections())
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Admin)
);
