import { request as apiRequest } from "../../api/api";
import { isRequestPending } from "./selectors";

export const API_REQUEST_PENDING = "API_REQUEST_PENDING";
export const API_REQUEST_READY = "API_REQUEST_READY";

export const apiRequestPending = requestId => ({
  type: API_REQUEST_PENDING,
  payload: requestId
});
export const apiRequestReady = requestId => ({
  type: API_REQUEST_READY,
  payload: requestId
});

export const request = (dispatch, getState) => async (
  requestId,
  params,
  successAction,
  errorAction
) => {
  if (isRequestPending(getState())(requestId)) {
    return;
  }
  dispatch(apiRequestPending(requestId));
  try {
    const accessToken = getState().user.token;
    const result = await apiRequest(requestId, params, accessToken);

    dispatch(apiRequestReady(requestId));
    dispatch(successAction(result.data));
  } catch (err) {
    dispatch(apiRequestReady(requestId));
    if (err.response) {
      dispatch(errorAction(err.response));
    }
    console.error(
      "The server cannot be reached, generic error will dispatch here.",
      err
    );
  }
};
