import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectionControl, TextField, FileInput } from "react-md";
import { S3_BUCKET_URL } from "../../../../Config";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { uploadImage, deleteImage } from "../../../../state/admin/actions";
import { isRequestPending } from "../../../../state/api/selectors";

import "./CollectionCard.scss";

class CollectionCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			title: this.props.collection.title || "",
			order: this.props.collection.order || "",
			images: this.props.collection.images || []
		};

		this.placeholder = document.createElement("div");
		this.placeholder.className = "placeholder";

		this.renderImage = this.renderImage.bind(this);
		this.handleImageUpLoad = this.handleImageUpLoad.bind(this);
		this.onOrderChange = this.onOrderChange.bind(this);
		this.onDisplayChange = this.onDisplayChange.bind(this);
		this.onTitleChange = this.onTitleChange.bind(this);
		this.updateOrder = this.updateOrder.bind(this);
		this.updateTitle = this.updateTitle.bind(this);
		this.onRemoveImageClick = this.onRemoveImageClick.bind(this);
		this.dragStart = this.dragStart.bind(this);
		this.dragEnd = this.dragEnd.bind(this);
		this.dragOver = this.dragOver.bind(this);
		this.deleteCollection = this.deleteCollection.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.collection.images &&
			this.props.collection.images.length !== prevState.images.length
		) {
			const images = this.props.collection.images || [];
			this.setState({ images: images });
		}
	}

	dragStart(e) {
		this.dragged = e.currentTarget;

		e.dataTransfer.effectAllowed = "move";
		e.dataTransfer.setData("text/html", this.dragged);
	}

	dragEnd(e) {
		const images = this.state.images;
		const fromIndex = images.indexOf(this.dragged.dataset.id);
		const toIndex = images.indexOf(this.over ? this.over.dataset.id : 0);
		const parentNode = this.dragged.parentNode;

		this.dragged.style.display = "flex";

		if (
			parentNode &&
			parentNode.getElementsByClassName("placeholder").length > 0
		) {
			parentNode.removeChild(this.placeholder);
		}

		images.splice(toIndex, 0, images.splice(fromIndex, 1)[0]);

		this.setState({ images: images }, this.updateImageOrder);
	}

	dragOver(e) {
		e.preventDefault();

		this.dragged.style.display = "none";

		if (e.target.className === "placeholder") {
			return;
		}

		let thumbnail = e.target;

		if (
			thumbnail.className.indexOf("thumbnail") === -1 &&
			thumbnail.tagName !== "DIV"
		) {
			thumbnail = thumbnail.parentNode;
		} else {
			return;
		}

		this.over = thumbnail;

		const images = this.state.images;

		const fromIndex = images.indexOf(this.dragged.dataset.id);
		const toIndex = images.indexOf(this.over.dataset.id);

		if (thumbnail.parentNode.className.indexOf("collection-images") > -1) {
			let referenceNode = thumbnail;

			if (fromIndex < toIndex) {
				referenceNode = thumbnail.nextSibling;
			}

			thumbnail.parentNode.insertBefore(this.placeholder, referenceNode);
		}
	}

	renderImage(imageName, i) {
		let image = S3_BUCKET_URL + encodeURIComponent(imageName);

		return (
			<div
				data-id={imageName}
				key={imageName}
				className="thumbnail"
				draggable="true"
				onDragEnd={this.dragEnd}
				onDragStart={this.dragStart}
			>
				<i
					className="material-icons cancel"
					image={imageName}
					onClick={this.onRemoveImageClick}
				>
					cancel
				</i>
				<img
					className="collection-image"
					src={image}
					alt="Collection"
				/>
				<label className="image-name">{imageName}</label>
			</div>
		);
	}

	onRemoveImageClick(event) {
		const imageName = event.target.getAttribute("image");
		const newCollection = Object.assign({}, this.props.collection);
		const index = newCollection.images.indexOf(imageName);
		newCollection.images.splice(index, 1);

		this.props.updateCollection(newCollection);
		this.props.deleteImage(imageName);
	}

	handleImageUpLoad(file) {
		if (file) {
			this.props.uploadImage(file, this.props.collection);
		}
	}

	onOrderChange(order) {
		this.setState({ order: order });
	}

	onTitleChange(title) {
		this.setState({ title: title });
	}

	onDisplayChange(checked, event) {
		const newCollection = Object.assign({}, this.props.collection);
		newCollection.display = checked;

		this.props.updateCollection(newCollection);
	}

	updateOrder(event) {
		const newCollection = Object.assign({}, this.props.collection);
		newCollection.order = this.state.order;

		this.props.updateCollection(newCollection);
	}

	updateTitle(event) {
		const newCollection = Object.assign({}, this.props.collection);
		newCollection.title = this.state.title;

		this.props.updateCollection(newCollection);
	}

	updateImageOrder() {
		const newCollection = Object.assign({}, this.props.collection);
		newCollection.images = this.state.images;

		this.props.updateCollection(newCollection);
	}

	deleteCollection() {
		this.props.deleteCollection(this.props.collection.id);
	}

	render() {
		const { id, display = false } = this.props.collection;
		const { pendingUploadRequest, pendingDeleteRequest } = this.props;

		return (
			<div className="collection-card" key={id}>
				{pendingUploadRequest || pendingDeleteRequest ? <ProgressBar /> : (
					<React.Fragment>
						<div className="card-header">
							<TextField
								id={"title-field-" + id}
								label="Title"
								lineDirection="center"
								className="md-cell md-cell--bottom"
								value={this.state.title}
								onChange={this.onTitleChange}
								onBlur={this.updateTitle}
							/>
							<TextField
								id={"order-field-" + id}
								label="Order"
								lineDirection="center"
								className="md-cell md-cell--bottom order"
								value={this.state.order}
								onChange={this.onOrderChange}
								onBlur={this.updateOrder}
							/>
							<FileInput
								id={"file-input-" + id}
								accept="image/*"
								name="images"
								flat
								label="Add image"
								allowDuplicates
								onChange={this.handleImageUpLoad}
							/>
							<i
								className="material-icons"
								onClick={this.deleteCollection}
							>
								delete
							</i>
							<SelectionControl
								id={"switch-" + id}
								type="switch"
								label=""
								name="display"
								checked={display}
								onChange={this.onDisplayChange}
							/>
						</div>
						<div className="card-body">
							<div
								className="collection-images"
								onDragOver={this.dragOver}
							>
								{Array.from(this.state.images).map(this.renderImage)}
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	pendingUploadRequest: isRequestPending(state)("uploadAdminImage"),
	pendingDeleteRequest: isRequestPending(state)("deleteAdminImage")
});

const mapDispatchToProps = dispatch => ({
	uploadImage: (file, collection) => dispatch(uploadImage(file, collection)),
	deleteImage: image => dispatch(deleteImage(image))
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionCard);
