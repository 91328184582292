import * as ActionTypes from "./actions";

export const work = (state = [], { type, payload }) => {
	switch (type) {
		case ActionTypes.RECEIVED_WORK_SUCCESS:
			return payload;
		case ActionTypes.REMOVE_WORK:
			return payload;
		default:
			return state;
	}
};
