import * as ActionTypes from "./actions";

export const user = (state = { auth: false }, { type, payload }) => {
	switch (type) {
		case ActionTypes.LOG_IN_SUCCESS:
			return payload;
		case ActionTypes.LOG_IN_ERROR:
			return payload;
		case ActionTypes.IS_AUTHENTICATED_SUCCESS: {
			return payload;
		}
		case ActionTypes.IS_AUTHENTICATED_ERROR: {
			return { auth: false };
		}
		case ActionTypes.LOG_OUT_SUCCESS: {
			return { auth: false };	
		}
		default:
			return state;
	}
};
