import { createStore, applyMiddleware, combineReducers } from "redux";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { work } from "./work/reducers";
import { user } from "./auth/reducers";
import { api } from "./api/reducers";
import { cover } from "./cover/reducers";
import { about } from "./about/reducers";
import { imageList, collections } from "./admin/reducers";
import { gallery } from "./gallery/reducers";
import { emailStatus } from "./contact/reducers";

export default function configureStore(initialState) {
    const rootReducer = combineReducers({
        work,
        user,
        imageList,
        collections,
        cover,
        about,
        api,
        gallery,
        emailStatus
    });
    const middlewares = [thunkMiddleware];

    if (process.env.NODE_ENV === "development") {
        middlewares.push(logger);
    }

    const store = createStore(
        rootReducer,
        initialState,
        composeWithDevTools(applyMiddleware(...middlewares))
    );

    return store;
}
