import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({ text }) => (
	<div className="progress-bar">
	    <div className="lds-ring">
	      <div />
	      <div />
	      <div />
	      <div />
	    </div>
  	</div>
);

export default ProgressBar;
