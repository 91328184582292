import * as ActionTypes from "./actions";

export const cover = (state = {}, { type, payload }) => {
	switch (type) {
		case ActionTypes.RECEIVED_COVER_SUCCESS:
			return payload;
		default:
			return state;
	}
};
