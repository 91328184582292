import { request } from "../api/actions";

export const RECEIVED_COVER_SUCCESS = "RECEIVED_COVER_SUCCESS";
export const RECEIVED_COVER_ERROR = "RECEIVED_COVER_ERROR";

export const getCoverSuccess = result => ({
  type: RECEIVED_COVER_SUCCESS,
  payload: result[0]
});

export const getCoverError = err => ({
  type: RECEIVED_COVER_ERROR,
  payload: err
});

export const fetchCover = () => async (dispatch, getState) => {
  request(dispatch, getState)(
    "getCover",
    {},
    getCoverSuccess,
    getCoverError
  );
};
