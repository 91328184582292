import { API_REQUEST_PENDING, API_REQUEST_READY } from "./actions";

export const api = (state = { pendingRequests: [] }, action) => {
  switch (action.type) {
    case API_REQUEST_PENDING:
      return {
        ...state,
        pendingRequests: [...state.pendingRequests, action.payload]
      };
    case API_REQUEST_READY:
      return {
        ...state,
        pendingRequests: state.pendingRequests.filter(
          el => el !== action.payload
        )
      };
    default:
      return state;
  }
};

export default api;
