import React from "react";
import instagramLogo from "../../../resources/icons/instagram.png";
import "./InstagramLogo.scss";

const InstagramLogo = ({ history, hidden }) => {
	function onInstagramClick() {
		window.open("https://www.instagram.com/zoltanmihaly/", "_blank");
	}

	return (
		<img
			onClick={onInstagramClick}
			className={hidden ? "instagram-logo hidden" : "instagram-logo"}
			src={instagramLogo}
			alt="Instagram"
		/>
	);
};

export default InstagramLogo;
