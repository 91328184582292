import { request } from "../api/actions";

export const CONTACT_EMAIL_SENT = "CONTACT_EMAIL_SENT";
export const CONTACT_EMAIL_FAILED = "CONTACT_EMAIL_FAILED";

export const sendEmailSuccess = result => {
  if (result.statusCode === 400) {
    return {
      type: CONTACT_EMAIL_FAILED,
      payload: result.errors
    };
  }
  return {
    type: CONTACT_EMAIL_SENT,
    payload: result
  };
};

export const sendEmailError = err => ({
  type: CONTACT_EMAIL_FAILED,
  payload: err
});

export const sendEmail = data => async (dispatch, getState) => {
  request(dispatch, getState)(
    "sendEmail",
    {
      data: data
    },
    sendEmailSuccess,
    sendEmailError
  );
};
