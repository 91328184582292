import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { S3_BUCKET_URL } from "../../../Config";
import { deleteImage } from "../../../state/admin/actions";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import {
	DataTable,
	TableHeader,
	TableBody,
	TableRow,
	TableColumn
} from "react-md";

import { fetchImageList } from "../../../state/admin/actions";
import { isRequestPending } from "../../../state/api/selectors";

import "./Images.scss";

class Images extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			confirmationDialogVisible: false,
			selectedImage: null
		};

		this.onDeleteIconClick = this.onDeleteIconClick.bind(this);
		this.renderTableRow = this.renderTableRow.bind(this);
	}

	componentDidMount() {
		if (this.props.isAuthenticated) {
			this.props.fetchImageList();
		}
	}

	async onDeleteIconClick(imageName) {
		this.setState({
			selectedImage: imageName,
			confirmationDialogVisible: true
		});

		await this.props.deleteImage(imageName);
		this.props.fetchImageList();
	}

	renderTableRow(imageName) {
		let image = S3_BUCKET_URL + encodeURIComponent(imageName);

		return (
			<TableRow key={imageName}>
				<TableColumn>{imageName}</TableColumn>
				<TableColumn className="preview-column">
					<img className="gallery-image" src={image} alt="Gallery" />
				</TableColumn>
				<TableColumn>
					<i
						className="material-icons"
						onClick={() => {
							this.onDeleteIconClick(imageName);
						}}
					>
						delete
					</i>
				</TableColumn>
			</TableRow>
		);
	}

	render() {
		const { isRequestPending, pendingDeleteRequest } = this.props;

		return (
			<DataTable
				baseId="image-gallery-table"
				plain
				className="image-list"
			>
				<TableHeader>
					<TableRow>
						<TableColumn />
						<TableColumn />
						<TableColumn />
					</TableRow>
				</TableHeader>
				{isRequestPending || pendingDeleteRequest ? (
					<ProgressBar />
				) : (
					<TableBody>
						{Array.from(this.props.imageList).map(
							this.renderTableRow
						)}
					</TableBody>
				)}
			</DataTable>
		);
	}
}

const mapStateToProps = state => ({
	isAuthenticated: state.user.auth,
	imageList: state.imageList,
	pendingRequest: isRequestPending(state)("getAdminImages"),
	pendingDeleteRequest: isRequestPending(state)("deleteAdminImage")
});

const mapDispatchToProps = dispatch => ({
	fetchImageList: () => dispatch(fetchImageList()),
	deleteImage: image => dispatch(deleteImage(image))
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Images)
);
