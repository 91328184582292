import axios from "axios";
import pathToRegexp from "path-to-regexp";
import { endpoints } from "../config/endpoints";

const BASE_URL = "/api";
const http = axios.create({ baseURL: BASE_URL });

export async function request(
  requestId,
  { pathParams = {}, data = {}, queryParams = {} } = {},
  accessToken
) {
  const endpoint = endpoints[requestId];

  const requestAxiosConfig = {
    ...endpoint,
    credentials: "same-origin",
    headers: {
       "x-access-token": accessToken || ""
    },
    url: pathToRegexp.compile(endpoint.url)(pathParams),
    data,
    params: queryParams
  };

  return http.request(requestAxiosConfig);
}

export default request;
