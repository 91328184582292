import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NavigationBar from "../components/NavigationBar/NavigationBar";
import InstagramLogo from "../components/InstagramLogo/InstagramLogo";
import { Button, TextField } from "react-md";

import ProgressBar from "../components/ProgressBar/ProgressBar";
import { isRequestPending } from "../../state/api/selectors";
import { sendEmail } from "../../state/contact/actions";

import "./Contact.scss";

class Contact extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			email: "",
			message: "",
			sendingStatus: ""
		};

		this.onLogoClick = this.onLogoClick.bind(this);
		this.onNameChange = this.onNameChange.bind(this);
		this.onEmailChange = this.onEmailChange.bind(this);
		this.onMessageChange = this.onMessageChange.bind(this);
		this.onSend = this.onSend.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { emailStatus } = this.props;

		if (emailStatus.status !== prevState.sendingStatus) {
			this.setState({ sendingStatus: emailStatus.status });
		}
	}

	onLogoClick() {
		this.props.history.push("");
	}

	onNameChange(value) {
		this.setState({ name: value });
	}

	onEmailChange(value) {
		this.setState({ email: value });
	}

	onMessageChange(value) {
		this.setState({ message: value });
	}

	async onSend() {
		await this.props.sendEmail(this.state);
		this.setState({
			name: "",
			email: "",
			message: "",
			sendingStatus: ""
		});
	}

	render() {
		const { name, email, message, sendingStatus } = this.state;
		const { pendingRequest } = this.props;

		return (
			<article className="contact">
				<header className="logo-container" onClick={this.onLogoClick}>
					<div role="navigation" aria-label="Cover" className="logo" />
				</header>
				{pendingRequest ? (
					<ProgressBar />
				) : (
					<div className="contact-container">
						<form className="contact-form">
							<TextField
								id="name-field"
								label="Name"
								lineDirection="center"
								className="md-cell"
								value={name}
								onChange={this.onNameChange}
							/>
							<TextField
								id="email-field"
								label="Email"
								type="email"
								className="md-cell"
								value={email}
								onChange={this.onEmailChange}
							/>
							<TextField
								id="message-field"
								label="Message"
								className="md-cell message"
								value={message}
								rows={8}
								onChange={this.onMessageChange}
							/>
						</form>
						<Button
							raised
							disabled={!name || !email || !message}
							className="send-button"
							onClick={this.onSend}
						>
							Send
						</Button>
						<div className="contact-label-container">
							<span className="contact-label">
								+36 30 875 5862
							</span>
						</div>
						<div className="email-status">
							{sendingStatus === "Success" ? (
								"Thank you for your message."
							) : sendingStatus === "Faulire" ? (
								<div className="email-error">
									<div>Failed to send contact message!</div>
								</div>
							) : (
								""
							)}
						</div>
					</div>
				)}

				<NavigationBar />
				<InstagramLogo />
				<span className="all-rights-reserved">All rights reserved</span>
			</article>
		);
	}
}

const mapStateToProps = state => ({
	pendingRequest: isRequestPending(state)("sendEmail"),
	emailStatus: state.emailStatus
});

const mapDispatchToProps = dispatch => ({
	sendEmail: data => dispatch(sendEmail(data))
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Contact)
);
