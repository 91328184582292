import React from "react";
import { withRouter } from "react-router-dom";

import "./NavigationBar.scss";

const NavigationBar = ({ history, hidden }) => {
	function navigate(event) {
		const target = event.target;
		const hashUrl = target.getAttribute("hash");
		if (!hidden) {
			history.push(hashUrl);
		}
	}

	const pathName = history.location.pathname;
	const activeItem = pathName.substring(1, pathName.length);

	return (
		<nav className={hidden ? "navigation-bar hidden" : "navigation-bar"}>
			<button
				className={
					activeItem === "work"
						? "selected navigation-button"
						: "navigation-button"
				}
				type="button"
				hash="work"
				onClick={navigate}
			>
				Work
			</button>
			<button
				className={
					activeItem === "contact"
						? "selected navigation-button"
						: "navigation-button"
				}
				type="button"
				hash="contact"
				onClick={navigate}
			>
				Contact
			</button>
			<button
				className={
					activeItem === "about"
						? "selected navigation-button"
						: "navigation-button"
				}
				type="button"
				hash="about"
				onClick={navigate}
			>
				About
			</button>
		</nav>
	);
};

export default withRouter(NavigationBar);
