import * as ActionTypes from "./actions";

export const emailStatus = (
	state = {
		errors: [],
		status: ""
	},
	{ type, payload }
) => {
	switch (type) {
		case ActionTypes.CONTACT_EMAIL_SENT:
			return {
				errors: [],
				status: "Success"
			};
		case ActionTypes.CONTACT_EMAIL_FAILED:
			return {
				errors: payload,
				status: "Faulire"
			};
		default:
			return state;
	}
};
