import { request } from "../api/actions";

export const RECEIVED_WORK_SUCCESS = "RECEIVED_WORK_SUCCESS";
export const RECEIVED_WORK_ERROR = "RECEIVED_WORK_ERROR";
export const REMOVE_WORK = "REMOVE_WORK";

export function removeWork() {
    return {
        type: REMOVE_WORK,
        payload: []
    };
}

export const getWorkSuccess = result => ({
  type: RECEIVED_WORK_SUCCESS,
  payload: result
});

export const getWorkError = err => ({
  type: RECEIVED_WORK_ERROR,
  payload: err
});

export const fetchWork = () => async (dispatch, getState) => {
  request(dispatch, getState)(
    "getWork",
    {},
    getWorkSuccess,
    getWorkError
  );
};
