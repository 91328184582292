import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { S3_BUCKET_URL } from "../../Config";

import NavigationBar from "../components/NavigationBar/NavigationBar";
import InstagramLogo from "../components/InstagramLogo/InstagramLogo";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import { fetchAbout } from "../../state/about/actions";
import { isRequestPending } from "../../state/api/selectors";

import "./About.scss";

class About extends Component {
	constructor(props) {
		super(props);
		this.onLogoClick = this.onLogoClick.bind(this);
	}

	componentDidMount() {
		this.props.fetchAbout();
	}

	componentDidUpdate(prevProps) {
		if (this.props.previewAbout) {
			if (this.props.previewAbout.title !== prevProps.previewAbout.title ||
				this.props.previewAbout.images[0] !== prevProps.previewAbout.images[0]) {

				this.props.fetchAbout();
			}
		}
	}

	onLogoClick() {
		this.props.history.push("");
	}

	render() {
		let aboutImage = "";
		const { pendingRequest, about = {} } = this.props;
		const aboutText = about.title;

		if (about.images) {
			aboutImage = S3_BUCKET_URL + encodeURIComponent(about.images[0]);
		}

		return (
			<article className="about">
				<header className="logo-container" onClick={this.onLogoClick}>
					<div role="navigation" aria-label="Cover" className="logo" />
				</header>
				{pendingRequest ? (
					<ProgressBar />
				) : (
					<div className="text-container">
						<img
							alt="Portrait"
							src={aboutImage}
							className="portrait"
						/>
						<p>{aboutText}</p>
					</div>
				)}
				<NavigationBar />
				<InstagramLogo />
				<span className="all-rights-reserved">All rights reserved</span>
			</article>
		);
	}
}

const mapStateToProps = state => ({
	about: state.about,
	pendingRequest: isRequestPending(state)("getAbout")
});

const mapDispatchToProps = dispatch => ({
	fetchAbout: () => dispatch(fetchAbout())
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(About)
);
