import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { contain } from "intrinsic-scale";
import { S3_BUCKET_URL } from "../../../Config";
import leftArrow from "../../../resources/icons/left_arrow.png";
import rightArrow from "../../../resources/icons/right_arrow.png";
import { setFullScreen } from "../../../state/gallery/actions";

import "./Gallery.scss";

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      galleryIndex: 0,
      imageIndex: 0,
      titlePositions: {},
      hideDecoration: false,
      timer: 0
    };

    this.onKeyDown = this.onKeyDown.bind(this);
    this.navigateLeft = this.navigateLeft.bind(this);
    this.navigateRight = this.navigateRight.bind(this);
    this.getNextIndexes = this.getNextIndexes.bind(this);
    this.calculateCaptionPosition = this.calculateCaptionPosition.bind(this);
    this.recalculateCaptionPosition = this.recalculateCaptionPosition.bind(
      this
    );
    this.renderGallery = this.renderGallery.bind(this);
    this.renderImage = this.renderImage.bind(this);
    this.switchFullScreen = this.switchFullScreen.bind(this);
  }

  getNextIndexes(direction) {
    const { imageIndex, galleryIndex } = this.state;

    const lastGalleryIndex = this.props.galleries.length - 1;
    const currentGalleryLength = this.props.galleries[galleryIndex]
      ? this.props.galleries[galleryIndex].images.length - 1
      : 0;

    if (direction === "left") {
      if (imageIndex === 0) {
        if (galleryIndex === 0) {
          return {
            nextGalleryIndex: lastGalleryIndex,
            nextImageIndex:
              this.props.galleries[lastGalleryIndex].images.length - 1
          };
        }
        const previousGalleryLength =
          this.props.galleries[galleryIndex - 1].images.length - 1;
        return {
          nextGalleryIndex: galleryIndex - 1,
          nextImageIndex: previousGalleryLength
        };
      }

      return {
        nextGalleryIndex: galleryIndex,
        nextImageIndex: imageIndex - 1
      };
    }
    if (imageIndex === currentGalleryLength) {
      if (galleryIndex === lastGalleryIndex) {
        return { nextGalleryIndex: 0, nextImageIndex: 0 };
      }

      return {
        nextGalleryIndex: galleryIndex + 1,
        nextImageIndex: 0
      };
    }

    return {
      nextGalleryIndex: galleryIndex,
      nextImageIndex: imageIndex + 1
    };
  }

  onKeyDown(event) {
    if (event.keyCode === 39 || event.keyCode === 37) {
      const direction = event.keyCode === 39 ? "right" : "left";

      const { nextGalleryIndex, nextImageIndex } = this.getNextIndexes(
        direction
      );

      this.setState(
        {
          imageIndex: nextImageIndex,
          galleryIndex: nextGalleryIndex
        },
        this.recalculateCaptionPosition
      );
    }
  }

  navigateLeft() {
    const { nextGalleryIndex, nextImageIndex } = this.getNextIndexes("left");

    this.setState(
      {
        imageIndex: nextImageIndex,
        galleryIndex: nextGalleryIndex
      },
      this.recalculateCaptionPosition
    );
  }

  navigateRight() {
    const { nextGalleryIndex, nextImageIndex } = this.getNextIndexes("right");

    this.setState(
      {
        imageIndex: nextImageIndex,
        galleryIndex: nextGalleryIndex
      },
      this.recalculateCaptionPosition
    );
  }

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyDown, false);
    window.addEventListener(
      "orientationchange",
      this.recalculateCaptionPosition,
      false
    );
    window.addEventListener("resize", this.recalculateCaptionPosition, false);
  }

  calculateCaptionPosition(imageReference, titleReference) {
    const imageDomElement = ReactDOM.findDOMNode(this.refs[imageReference]);

    if (imageDomElement) {
      const titleDomElement = ReactDOM.findDOMNode(this.refs[titleReference]);

      if (titleDomElement) {
        const parentElement = titleDomElement.parentNode;
        const maxHeight = parentElement.clientHeight;
        const maxWidth = parentElement.clientWidth;

        const { width, height } = contain(
          maxWidth,
          maxHeight,
          imageDomElement.naturalWidth,
          imageDomElement.naturalHeight
        );
        const right = (maxWidth - width) / 2;
        const top = (maxHeight - height) / 2;
        const bottom = top - 10;

        titleDomElement.style.right = `${right}px`;
        titleDomElement.style.bottom = `${bottom}`;

        setTimeout(function() {
          titleDomElement.style.opacity = 0.7;
        }, 300);

        this.setState(prevState => {
          const { titlePositions } = prevState;
          titlePositions[imageReference] = {
            right,
            bottom,
            top,
            titleReference
          };

          return { titlePositions };
        });
      }
    }
  }

  recalculateCaptionPosition() {
    const { titlePositions } = this.state;
    const images = Object.keys(titlePositions);

    for (let i = 0; i < images.length; i += 1) {
      if (images[i] && titlePositions[images[i]]) {
        this.calculateCaptionPosition(
          images[i],
          titlePositions[images[i]].titleReference
        );
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown, false);
    window.removeEventListener(
      "orientationchange",
      this.recalculateCaptionPosition,
      false
    );
    window.removeEventListener(
      "resize",
      this.recalculateCaptionPosition,
      false
    );
  }

  renderImage(imageName, imageIndex, visibleGallery, galleryId, title) {
    const visibleImage = visibleGallery && this.state.imageIndex === imageIndex;
    const imageReference = `${imageName}-${galleryId}`;
    const titleReference = `image-title-${imageReference}`;
    const { right = 0, bottom = 0, top = 0 } =
      this.state.titlePositions[imageReference] || {};
    const { hideDecoration } = this.state;

    const captationStyle = {
      right: `${right}px`,
      bottom: `${bottom}px`
    };

    if (right === 0 && top === 0) {
      captationStyle.opacity = 0;
    }

    return (
      <div
        className={visibleImage ? "image-container" : "image-container hidden"}
        key={imageReference}
      >        
        <img alt="arrow-left" className="arrow" src={leftArrow} />
        <img
          className="image"
          src={S3_BUCKET_URL + encodeURIComponent(imageName)}
          key={imageName}
          alt="Current"
          ref={imageReference}
          onLoad={({ target }) => {
            this.calculateCaptionPosition(imageReference, titleReference);
          }}
          onClick={this.switchFullScreen}
        />
        <img alt="arrow-right" className="arrow" src={rightArrow} />
        <figcaption
          style={captationStyle}
          ref={titleReference}
          className={hideDecoration ? "image-title hidden" : "image-title"}
        >
          {title}
        </figcaption>
      </div>
    );
  }

  renderGallery(gallery, index) {
    const { images = [], title, id } = gallery;
    const visibleGallery = this.state.galleryIndex === index;

    return (
      <React.Fragment key={id}>
        {images.map((imageName, imageIndex) =>
          this.renderImage(
            imageName,
            imageIndex,
            visibleGallery,
            id,
            title
          )
        )}
      </React.Fragment>
    );
  }

  switchFullScreen() {
    const { fullScreen, setFullScreen } = this.props;
    const { timer } = this.state;

    setFullScreen(!fullScreen);
    if (timer > 0) {
      clearTimeout(timer);
    }

    this.setState({
      hideDecoration: true,
      timer: setTimeout(() => {
        this.setState(
          {
            hideDecoration: false,
            timer: 0
          },
          this.recalculateCaptionPosition
        );
      }, 1000)
    });
  }

  render() {
    const { fullScreen } = this.props;

    return (
      <div className={fullScreen ? "fullScreen gallery" : "gallery"}>
        <div className="navigation-container">
          <div
            className="left-click-area click-area"
            onClick={this.navigateLeft}
          />
          {this.props.galleries.map(this.renderGallery)}
          <div
            className="right-click-area click-area"
            onClick={this.navigateRight}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  setFullScreen: fullScreen => dispatch(setFullScreen(fullScreen))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gallery);
