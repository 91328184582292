import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
	fetchWork,
	removeWork
} from "../../state/work/actions";
import { getFullScreen } from "../../state/gallery/selectors";

import NavigationBar from "../components/NavigationBar/NavigationBar";
import InstagramLogo from "../components/InstagramLogo/InstagramLogo";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import Gallery from "../components/Gallery/Gallery";
import { isRequestPending } from "../../state/api/selectors";

import "./Work.scss";

class Work extends Component {
	constructor(props) {
		super(props);

		this.onLogoClick = this.onLogoClick.bind(this);
	}

	componentDidMount() {
		this.props.removeWork();
		this.props.fetchWork();
	}

	onLogoClick() {
		this.props.history.push("");
	}

	render() {
		const { pendingRequest, work, fullScreen } = this.props;

		return (
			<article className="work">
				<header
					className={
						fullScreen ? "logo-container hidden" : "logo-container"
					}
					onClick={this.onLogoClick}
				>
					<div role="navigation" aria-label="Cover" className="logo" />
				</header>

				{pendingRequest ? (
					<ProgressBar />
				) : (
					<Gallery galleries={work} fullScreen={fullScreen} />
				)}

				<NavigationBar hidden={fullScreen} />
				<InstagramLogo hidden={fullScreen} />
				<span className="all-rights-reserved">All rights reserved</span>
			</article>
		);
	}
}

const mapStateToProps = state => ({
	work: state.work,
	pendingRequest: isRequestPending(state)("getWork"),
	fullScreen: getFullScreen(state)
});

const mapDispatchToProps = dispatch => ({
	removeWork: () => dispatch(removeWork()),
	fetchWork: () => dispatch(fetchWork())
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Work)
);
