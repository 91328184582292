import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectField, FileInput } from "react-md";

import Cover from "../../Cover/Cover";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import {
	uploadImage,
	fetchImageList,
	updateCollection,
	fetchCollections
} from "../../../state/admin/actions";
import { getCover } from "../../../state/admin/selectors";
import { isRequestPending } from "../../../state/api/selectors";

import "./CoverConfig.scss";

class CoverConfig extends Component {
	constructor(props) {
		super(props);

		this.handleImageUpLoad = this.handleImageUpLoad.bind(this);
		this.onUpdateCover = this.onUpdateCover.bind(this);
	}

	componentDidMount() {
		if (this.props.isAuthenticated) {
			this.props.fetchCollections();
			this.props.fetchImageList();
		} else {
			this.props.history.push("/login");
		}
	}

	getNewCover(newImage) {
		const newCover = Object.assign({}, this.props.cover);
		newCover.images = [newImage];

		return newCover;
	}

	handleImageUpLoad(file) {
		if (file) {
			const newCover = this.getNewCover(file.name);
			this.props.uploadImage(file, newCover);
		}
	}

	onUpdateCover(value) {
		const newCover = this.getNewCover(value);

		this.props.updateCollection(newCover);
	}

	render() {
		const { pendingUploadRequest } = this.props;

		let coverImage = "";

		if (this.props.cover && this.props.cover.images) {
			coverImage = this.props.cover.images[0];
		}

		return (
			<div className="cover-config">
				{pendingUploadRequest && <ProgressBar />}
				<div className="config-fields">
					<SelectField
						id="select-field-5"
						label="Cover image"
						className="md-cell"
						menuItems={this.props.imageList}
						position={SelectField.Positions.BELOW}
						value={coverImage}
						onChange={this.onUpdateCover}
					/>
					<FileInput
						id={"file-input"}
						accept="image/*"
						name="images"
						flat
						label="Add new image"
						onChange={this.handleImageUpLoad}
					/>
				</div>
				<div className="preview">
					<span>Preview</span>
					<Cover previewImage={coverImage} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	isAuthenticated: state.user.auth,
	cover: getCover(state),
	imageList: state.imageList,
	pendingUploadRequest: isRequestPending(state)("uploadAdminImage")
});

const mapDispatchToProps = dispatch => ({
	uploadImage: (file, collection) => dispatch(uploadImage(file, collection)),
	fetchCollections: () => dispatch(fetchCollections()),
	fetchImageList: () => dispatch(fetchImageList()),
	updateCollection: collection => dispatch(updateCollection(collection))
});

export default connect(mapStateToProps, mapDispatchToProps)(CoverConfig);
