import * as ActionTypes from "./actions";

export const imageList = (state = [], { type, payload }) => {
	switch (type) {
		case ActionTypes.RECEIVED_IMAGE_LIST_SUCCESS:
			return payload;
		case ActionTypes.IMAGE_DELETE_SUCCESS: {
			const index = state.indexOf(payload);

			if (index > -1) {
				return [...state.slice(0, index), ...state.slice(index + 1)];
			}
			return state;
		}
		default:
			return state;
	}
};

export const collections = (state = [], { type, payload }) => {
	switch (type) {
		case ActionTypes.RECEIVED_COLLECTIONS_SUCCESS:
			return payload;
		case ActionTypes.COLLECTION_UPDATE_SUCCESS:
			const updatedItems = state.map(item => {
				if (item.id === payload.id) {
					return { ...item, ...payload };
				}
				return item;
			});

			return updatedItems;
		case ActionTypes.COLLECTION_ADD_SUCCESS: {
			let newCollection = state.slice();
			newCollection.push(payload);

			return newCollection;
		}
		case ActionTypes.COLLECTION_DELETE_SUCCESS:
			const index = state.findIndex(item => item.id === payload.id);
			const remainingItems = [
				...state.slice(0, index),
				...state.slice(index + 1)
			];

			return remainingItems;
		default:
			return state;
	}
};
